import Alpine from "alpinejs";
import htmx from "htmx.org";
import focus from '@alpinejs/focus'
import headerCircleAnimation from "./header-circle-animation";
import bgCircleAnimation from "./bg-circle-animation";
import Swiper from "swiper/bundle";
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'

window.Swiper = Swiper;
window.Alpine = Alpine;
window.htmx = htmx;

Alpine.plugin(intersect);
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.data("headerCircleAnimation", headerCircleAnimation);
Alpine.data("bgCircleAnimation", bgCircleAnimation);

// if (window.htmx) {
// 	import("htmx-ext-head-support").then(() => {
// 		console.log("htmx-ext-head-support loaded");
// 	});
// }
if(nonce !== undefined) {
    document.addEventListener('htmx:configRequest', function(event) {
        event.detail.headers['X-WP-Nonce'] = nonce;
    });
}

document.addEventListener('DOMContentLoaded', function() {
    Alpine.start();
});
